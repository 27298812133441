import { Col } from "@amzn/stencil-react-components/layout";
import { Text } from "@amzn/stencil-react-components/text";
import React from "react";
import { FormattedMessage } from "react-intl";

import ErrorBanner from "@/components/Commons/ErrorBanner/ErrorBanner";

/**
 * The error message to inform NHs when they are ineligible for Day1 onboarding since we accidentally notified all new
 * hires instead of the targeted new hires in our Cohort filter.
 * TODO: Update or remove this error message later.
 */
export const IneligibleError = (): JSX.Element => {
  return (
    <ErrorBanner>
      <Col gridGap="S200">
        <Text dataTestId="error-ineligibleError-text1">
          <FormattedMessage id="error-ineligibleError-text1" />
        </Text>
        <Text dataTestId="error-ineligibleError-text2">
          <FormattedMessage id="error-ineligibleError-text2" />
        </Text>
        <Text dataTestId="error-ineligibleError-text3">
          <FormattedMessage id="error-ineligibleError-text3" />
        </Text>
        <Text dataTestId="error-ineligibleError-text4">
          <FormattedMessage id="error-ineligibleError-text4" />
        </Text>
      </Col>
    </ErrorBanner>
  );
};

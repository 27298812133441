import "./style.scss";

import {
  Button,
  ButtonSize,
  ButtonVariant,
} from "@amzn/stencil-react-components/button";
import { Expander } from "@amzn/stencil-react-components/expander";
import { Flyout, FlyoutContent } from "@amzn/stencil-react-components/flyout";
import { Col, Hr, Row } from "@amzn/stencil-react-components/layout";
import { Text } from "@amzn/stencil-react-components/text";
import { ReactNode, useContext, useMemo } from "react";
import { IntlShape, useIntl } from "react-intl";

import { STEP_ID } from "@/components/constants";
import { AppContext } from "@/stores/appStore";
import { ActionType } from "@/stores/constants";

// Given if we have multiple section<n> values like "getSupport.faq.<step>.section<3>.contents", get the relation of step ID to FAQ section string
const STEP_TO_FAQ_MAP: Readonly<Record<STEP_ID, string>> = {
  [STEP_ID.IDENTITY_CHECK]: "general",
  [STEP_ID.AMAZON_USERNAME]: "username",
  [STEP_ID.REGISTER_SECURITY_KEY]: "midway",
  [STEP_ID.CONNECT_VPN]: "vpn",
  [STEP_ID.AMAZON_PASSWORD]: "password",
  [STEP_ID.PRE_CACHE_AD_MOBILE]: "general",
};

/**
 * Renders an HTML element which is used for displaying an expander title.
 * @param text Text string to use via i18n. This is the actual text not the i18n ID such as using intl.formatMessage(...)
 * @param toggle Toggle function reference passed by the renderTitle method of Expander.
 *               Allows the expander to be toggled by clicking the caret or the text.
 */
const renderTitle = (text: string, toggle: () => void): ReactNode => {
  return (
    <Text
      color="primary70"
      fontSize="T200"
      onClick={() => toggle()}
      className="cursor-pointer"
    >
      {text}
    </Text>
  );
};

/**
 * Maps the section step number to an i18n section identifier
 * i18n ID follows the pattern of {Key: Value} and the keys used for the getSupport FAQs follow this pattern:
 *    getSupport.faq.<step>.section<n>.{title,contents}
 * @returns a list of records containing the key title or contents and the contents to display as a string.
 */
const mapStepNumberToFAQs = (
  intlProvider: IntlShape,
  stepId?: STEP_ID
): Record<"title" | "contents", string>[] => {
  // Check step undefined: addresses bug where transitioning between states with support panel open causes a currentStep not defined error.
  const currentStepToFaqStep = stepId ? STEP_TO_FAQ_MAP[stepId] : "general";
  const baseName = `getSupport-faq-${currentStepToFaqStep}-section`;

  const results: Record<"title" | "contents", string>[] = [];

  /*
   The upper bound of items is unknown without iterating through the entire intlProvider.messages[] to map regex values and this is very inefficient.
   Instead, check if the key is present or exit loop. The section ID numbers MUST be sequential.

   Notes:
   1. ** This requires the format of the FAQ IDs to not change. See `baseName` var. **
   2. The ID sections start from 1 not 0.
   3. Applies an upper limit of ** 25 items ** to ensure there isn't a run away loop.
  */
  const MAX_RESULTS = 25;
  for (let i = 1; i <= MAX_RESULTS; i++) {
    // Check if the key like `getSupport-faq-general-section1-title` exists for this index.
    if (intlProvider.messages[`${baseName}${i}-title`]) {
      // If it exists, push the title and contents into an object and stuff it into the results array.
      results.push({
        title: `${baseName}${i}-title`,
        contents: `${baseName}${i}-contents`,
      });
    } else {
      break;
    }
  }
  return results;
};

/**
 * Renders the Get Support flyout.
 */
export const GetSupportFlyout = (): JSX.Element => {
  const { state, dispatch } = useContext(AppContext);
  const intl = useIntl();

  // The state.currentStep updates prematurely. Instead of using that, find a reference to the first uncompleted step (before next button is clicked) which should be the step we're on now.
  const firstUncompletedStep = state.steps?.find((step) => !step.completed);

  // Get the FAQ content from intl based on the currently displayed step.
  // useMemo prevents this value from being recalculated more than once per step state change.
  const faqForStep = useMemo(() => {
    return mapStepNumberToFAQs(intl, firstUncompletedStep?.id);
  }, [state.currentStep]);

  // What to do when the Flyout close is called.
  const onCloseHandler = () => {
    dispatch({
      type: ActionType.SET_GET_SUPPORT_FLYOUT_VISIBLE,
      getSupportFlyoutVisible: false,
    });
  };

  // Handles clicking on the chat with IT support button.
  const openChatSupportHandler = () => {
    // Set flyout visibility
    dispatch({
      type: ActionType.SET_CHAT_SUPPORT_FLYOUT_VISIBLE,
      chatSupportFlyoutVisible: true,
    });
  };

  return (
    <Flyout
      close={onCloseHandler}
      shouldCloseOnClickOutside={true}
      dataTestId="get-support-flyout"
      isOpen={state.getSupportFlyoutVisible}
    >
      <FlyoutContent
        onCloseButtonClick={onCloseHandler}
        titleText={intl.formatMessage({ id: `getSupport-header` })}
      >
        <Row>
          <Text fontSize="T400">
            {intl.formatMessage({
              id: `getSupport-faq-${
                firstUncompletedStep
                  ? STEP_TO_FAQ_MAP[firstUncompletedStep.id]
                  : "general"
              }-header`,
            })}
          </Text>
        </Row>
        <Col height="20px" />
        <Hr color="neutral20" size="narrow" />
        <Col height="10px" />
        <Col gridGap="S200">
          {/* Generate FAQs for this step */}
          {faqForStep.map((faq, index) => {
            return (
              <div key={`${faq.title}-${index}`}>
                <Col height="3px" />
                <Row key={`${faq.title}-${index}`}>
                  <Expander
                    renderTitle={(params) => {
                      return renderTitle(
                        intl.formatMessage({ id: faq.title }),
                        params.toggle
                      );
                    }}
                    key={`key-${faq.title}-${index}`}
                    titleText={intl.formatMessage({ id: faq.title })}
                    dataTestId={`faq-item-${index}`}
                  >
                    <Text fontSize="T200">
                      {intl.formatMessage({ id: faq.contents })}
                    </Text>
                  </Expander>
                  <Hr color="neutral20" size="narrow" />
                </Row>
                <Col height="5px" />
                <Hr color="neutral20" size="narrow" />
              </div>
            );
          })}
        </Col>
        <Col height="25px" />
        <Row
          justifyContent="space-between"
          display="flex"
          alignItems="center"
          backgroundColor="primary05"
          height="75px"
          padding="20px"
        >
          <Text fontSize="T100">
            {intl.formatMessage({ id: "getSupport-footer" })}
          </Text>
          <Button
            variant={ButtonVariant.Secondary}
            onClick={openChatSupportHandler}
            size={ButtonSize.Small}
            dataTestId={"getSupportFlyoutLaunchChat"}
            aria-label={intl.formatMessage({
              id: "arialabels-clickToOpenGetSupport",
            })}
          >
            {intl.formatMessage({ id: "text-chatWithITSupport" })}
          </Button>
        </Row>
      </FlyoutContent>
    </Flyout>
  );
};

import "./style.scss";

import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { IconCloseCircleFill } from "@amzn/stencil-react-components/icons";
import { Row } from "@amzn/stencil-react-components/layout";
import { Text } from "@amzn/stencil-react-components/text";
import { trackStructEvent } from "@snowplow/browser-tracker";
import React, { useContext, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { EmbeddedMetric, Logger } from "@/apis/Logger";
import { ChatWidget } from "@/components/GetSupport";
import { AppContext } from "@/stores/appStore";
import { ActionType } from "@/stores/constants";
import {
  Day1EventAction,
  Day1EventCategory,
  Day1EventLabel,
} from "@/types/snowplow-events";

import VerifyWithItFlyoutContent from "../VerifyWithItFlyoutContent/VerifyWithItFlyoutContent";

/**
 * Implements the chat support flyout panel element.
 */
export const ChatSupportFlyout = () => {
  const { dispatch, state } = useContext(AppContext);
  const intl = useIntl();
  /**
   * Used to control the internal component state of if the Start Chat button has been clicked yet.
   * Since this component has to be mounted in the main HomeContent component and **present** in the DOM always
   * to enable it to be opened and closed independently of other components, we want to prevent the ChatWidget component
   * from loading before its wanted since it cannot be torn down easily and immediately on load starts a chat support contact.
   *
   * Setting this to true causes the ChatWidget component to be loaded and this is independent of the panel visibility Redux state.
   */
  const [initialized, setInitialized] = useState(false);

  const showStartChatButton = !initialized && !state.getIdvSupportFlyoutVisible;
  const showIdvFlyoutContent = !initialized && state.getIdvSupportFlyoutVisible;

  const setChatInitializedHandler = () => {
    setInitialized(true);
    Logger.publishWarnMetric(
      new EmbeddedMetric("InitializedChatWithSupport", "Count", 1),
      "New hire initiated chat with support",
      undefined,
      {
        amazonUsername: state.amazonUsername,
        region: state.activeRegion,
        refPage: state.chatSupportRefPage ?? "PageLevel",
      }
    );
    trackStructEvent({
      category: Day1EventCategory.PageLevel,
      action: Day1EventAction.ChatWithSupport,
      label: Day1EventLabel.StartSupportChat,
    });
  };

  /**
   * Sets the visibility of the chat support flyout on click.
   * @param isVisible Should the panel be visible?
   */
  const setVisibilityHandler = (isVisible: boolean) => {
    dispatch({
      type: ActionType.SET_CHAT_SUPPORT_FLYOUT_VISIBLE,
      chatSupportFlyoutVisible: isVisible,
    });
    dispatch({
      type: ActionType.SET_GET_IDV_SUPPORT_FLYOUT_VISIBLE,
      getIdvSupportFlyoutVisible: false,
    });
    trackStructEvent({
      category: Day1EventCategory.PageLevel,
      action: Day1EventAction.ChatWithSupport,
      label: isVisible
        ? Day1EventLabel.OpenChatSupportPanel
        : Day1EventLabel.CloseChatSupportPanel,
    });
  };

  /**
   * Calculates a class name for the panel contents depending on if the Get Support panel is open or not.
   * The GetSupport panel is a Stencil Flyout component which has a drop shadow by default, however the ChatSupportFlyout component is custom
   * and does not come out of the box with a drop shadow. If the GetSupport panel is open, it will cause a double shadow which is not a consistent experience.
   */
  const getFlyoutContentsClassNameString = () => {
    let classNameString = "custom-flyout-contents ";
    // visible if the panel is opened.
    if (state.chatSupportFlyoutVisible) {
      classNameString += "custom-flyout-contents-visible ";
    }
    // If the chat panel was opened without the get support panel open, it's missing a drop shadow that should be there.
    if (!state.getSupportFlyoutVisible) {
      classNameString += "custom-flyout-contents-visible-drop-shadow ";
    }
    return classNameString;
  };

  return (
    <div className="custom-flyout-container">
      {/* This is the invisible left side of the page and provides an on-click to hide the flyout when anywhere else but the flyout contents is clicked. */}
      <div
        className={`custom-flyout-container-left ${
          state.chatSupportFlyoutVisible
            ? "custom-flyout-container-left-visible"
            : ""
        }`}
        onClick={() =>
          // Only close overlay if it's already open.
          state.chatSupportFlyoutVisible && setVisibilityHandler(false)
        }
        role="button"
        tabIndex={0}
        onKeyDown={() => {
          return;
        }}
        data-test-id="chatFlyoutLeftContainer"
        aria-hidden={!state.chatSupportFlyoutVisible}
        aria-label={intl.formatMessage({ id: "arialabels-clickToCloseChat" })}
      />
      <div className={getFlyoutContentsClassNameString()}>
        <div className="custom-flyout-contents-header">
          <Text
            fontSize="T300"
            fontWeight="medium"
            className="custom-flyout-contents-header-contents"
          >
            <FormattedMessage
              id={
                state.getIdvSupportFlyoutVisible
                  ? "getSupport-header"
                  : "text-chatWithITSupport"
              }
            />
          </Text>
          {/* Speed bump to prevent chat from starting on page load. */}
          {/* This is the "Start chat" button in the chat support flyout. */}
          {showStartChatButton && (
            <Button
              variant={ButtonVariant.Secondary}
              onClick={() => setChatInitializedHandler()}
              dataTestId="chatClickToStart"
              aria-label={intl.formatMessage({
                id: "arialabels-clickToStartChat",
              })}
              aria-hidden={!showStartChatButton}
            >
              <FormattedMessage id="getSupport-chatSupport-clickToStart" />
            </Button>
          )}

          <Button
            icon={<IconCloseCircleFill aria-hidden={true} />}
            className="custom-flyout-contents-header-contents"
            onClick={() => setVisibilityHandler(false)}
            variant={ButtonVariant.Primary}
            dataTestId="chatFlyoutCloseBtn"
            aria-label={intl.formatMessage({
              id: "arialabels-clickToCloseChat",
            })}
            aria-hidden={false}
          />
        </div>
        <Row height="100%">
          {showIdvFlyoutContent ? (
            <VerifyWithItFlyoutContent
              chatInitializedHandler={setChatInitializedHandler}
            />
          ) : (
            <ChatWidget
              shouldInitialize={initialized}
              setVisibilityHandler={setVisibilityHandler}
              nonDirectedSupportCtx={state.nonDirectedChatSupportContext}
              refPage={state.chatSupportRefPage}
              refLink={state.chatSupportRefLink}
              username={state.amazonUsername}
            />
          )}
        </Row>
      </div>
    </div>
  );
};

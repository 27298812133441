import { ADA_RESOURCES } from "@amzn/client-workbench-test-utils/dist/lib/ada-resources";

import { STAGES } from "@/helpers/stageConfig";

export interface configType {
  USER_POOL_REGION: string;
  USER_POOL_ID: string;
  USER_POOL_APP: string;
  USER_POOL_DOMAIN: string;
  UNREGIONALIZED_API_ENDPOINT: string;
}

export const DEFAULT_REGION: ActivatedRegions = "us-west-2";
export type ActivatedRegions = "us-west-2" | "us-east-1";
const config: Record<STAGES, Record<ActivatedRegions, configType>> = {
  DEV: {
    "us-west-2": {
      USER_POOL_REGION: "us-west-2",
      USER_POOL_ID: "us-west-2_PQRuVmLGP",
      USER_POOL_APP: "5h4r27gd7hsop93dmubl323rh6",
      USER_POOL_DOMAIN: `amzn-nhe-ppv2-login-beta.auth.us-west-2.amazoncognito.com"`,
      UNREGIONALIZED_API_ENDPOINT: `https://api.beta.cwb.it.a2z.com`,
    },
    "us-east-1": {
      USER_POOL_REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_Q3Yglr5qq",
      USER_POOL_APP: "45kfvlrls4c2kehppf7pe1dikg",
      USER_POOL_DOMAIN: `amzn-nhe-ppv2-login-beta.auth.us-west-2.amazoncognito.com"`,
      UNREGIONALIZED_API_ENDPOINT: `https://api.beta.cwb.it.a2z.com`,
    },
  },

  BETA: {
    "us-west-2": {
      USER_POOL_REGION: "us-west-2",
      USER_POOL_ID: "us-west-2_PQRuVmLGP",
      USER_POOL_APP: "5h4r27gd7hsop93dmubl323rh6",
      USER_POOL_DOMAIN:
        "amzn-nhe-ppv2-login-beta.auth.us-west-2.amazoncognito.com",
      UNREGIONALIZED_API_ENDPOINT: "https://api.beta.cwb.it.a2z.com",
    },
    "us-east-1": {
      USER_POOL_REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_Q3Yglr5qq",
      USER_POOL_APP: "45kfvlrls4c2kehppf7pe1dikg",
      USER_POOL_DOMAIN:
        "amzn-nhe-ppv2-login-beta.auth.us-east-1.amazoncognito.com",
      UNREGIONALIZED_API_ENDPOINT: "https://api.beta.cwb.it.a2z.com",
    },
  },

  GAMMA: {
    "us-west-2": {
      USER_POOL_REGION: "us-west-2",
      USER_POOL_ID: "us-west-2_afbtddwye",
      USER_POOL_APP: "461pkhvs627eubsbbet89j0gpm",
      USER_POOL_DOMAIN:
        "amzn-nhe-ppv2-login-gamma.auth.us-west-2.amazoncognito.com",
      UNREGIONALIZED_API_ENDPOINT: "https://api.gamma.cwb.it.a2z.com",
    },
    "us-east-1": {
      USER_POOL_REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_qaSKsRGPE",
      USER_POOL_APP: "7khj5ev2ti0spvlff0u1q2njfu",
      USER_POOL_DOMAIN:
        "amzn-nhe-ppv2-login-gamma.auth.us-east-1.amazoncognito.com",
      UNREGIONALIZED_API_ENDPOINT: "https://api.gamma.cwb.it.a2z.com",
    },
  },
  PROD: {
    "us-west-2": {
      USER_POOL_REGION: "us-west-2",
      USER_POOL_ID: "us-west-2_2VlW3bcyK",
      USER_POOL_APP: "6qquq7av2emtduie4stf2kqfmt",
      USER_POOL_DOMAIN:
        "amzn-nhe-ppv2-login-prod.auth.us-west-2.amazoncognito.com",
      UNREGIONALIZED_API_ENDPOINT: "https://api.cwb.it.a2z.com",
    },
    "us-east-1": {
      USER_POOL_REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_FdHIsuwct",
      USER_POOL_APP: "10akqqle34g69cpjtl0ioq84dv",
      USER_POOL_DOMAIN:
        "amzn-nhe-ppv2-login-prod.auth.us-east-1.amazoncognito.com",
      UNREGIONALIZED_API_ENDPOINT: "https://api.cwb.it.a2z.com",
    },
  },
};

export default config;

export type Language = { label: string; locale: string };

export type languageObject = {
  [key: string]: string;
};

/**
 * If you need to add support for a new language for EZO, here are the steps:
 * 1. Modify the below LOCALES and SUPPORTED_LANGUAGES constants in this file.  This will ensure that the new language will be loaded into the application and messages render correctly,
 *    and that the new language is an available option for the user to select.
 * 2. Run `bb` to generate the correctly formatted translation files, and then bb server again afterwards to test.
 * 3. Run `bb server` to test and ensure the application is loading your new language and messages are rendering correctly.
 *
 * Note: Be sure to include the updated/new translation file(s) in your commit/CR located under src/GeneratedTranslations.
 **/

export const LOCALES: Record<string, string> = {
  ENGLISH: "en-US",
  // TODO: Re-Enable once we have consent language and page alignment issues sorted out
  // SIM: https://issues.amazon.com/issues/NHE-4292
  // ARABIC: "ar-AE",
  CZECH: "cs-CZ",
  GERMAN: "de-DE",
  SPANISH: "es-ES",
  FRENCH: "fr-FR",
  INDONESIAN: "id-ID",
  ITALIAN: "it-IT",
  JAPANESE: "ja-JP",
  KOREAN: "ko-KR",
  DUTCH: "nl-NL",
  POLISH: "pl-PL",
  PORTUGUESE: "pt-BR",
  ROMANIAN: "ro-RO",
  SLOVAK: "sk-SK",
  VIETNAMESE: "vi-VN",
  CHINESE: "zh-CN",
};

export const SUPPORTED_LANGUAGES: Language[] = [
  { label: "English - en", locale: "en-US" },
  // TODO: Re-Enable once we have consent language and page alignment issues sorted out
  // SIM: https://issues.amazon.com/issues/NHE-4292
  // { label: "العربية - ar", locale: "ar-AE" },
  { label: "Čeština - cs", locale: "cs-CZ" },
  { label: "Deutsch - de", locale: "de-DE" },
  { label: "Español - es", locale: "es-ES" },
  { label: "Français - fr", locale: "fr-FR" },
  { label: "Bahasa Indonesia - id", locale: "id-ID" },
  { label: "Italiano - it", locale: "it-IT" },
  { label: "日本語 - ja", locale: "ja-JP" },
  { label: "한국어 - ko", locale: "ko-KR" },
  { label: "Nederlands - nl", locale: "nl-NL" },
  { label: "Polski - pl", locale: "pl-PL" },
  { label: "Português - pt", locale: "pt-BR" },
  { label: "Română - ro", locale: "ro-RO" },
  { label: "Slovenčina - sk", locale: "sk-SK" },
  { label: "Tiếng Việt - vi", locale: "vi-VN" },
  { label: "中文 - zh", locale: "zh-CN" },
];

export const DEFAULT_LANGUAGE = SUPPORTED_LANGUAGES[0];

import { Row } from "@amzn/stencil-react-components/layout";
import React from "react";
import { useIntl } from "react-intl";
import { v4 as uuidv4 } from "uuid";

import { Image } from "@/types/common";

import { day1StepsImageClassName } from "../../constants";

interface ImagesRowProps {
  /** Image to be displayed. */
  images: Image[];

  /**
   * The padding of in between the Row container and the images.
   */
  rowPadding?: string;

  /**
   * The gap between adjacent images in the Row container
   */
  gridGap?: string;
}

/**
 * Render a Row container with the provided images at the center.
 *
 * @param props A ImagesRowProps interface
 * @constructor
 */
export default function ImagesRow({
  images,
  rowPadding,
  gridGap,
}: ImagesRowProps): JSX.Element {
  const intl = useIntl();

  return (
    <Row
      display="flex"
      backgroundColor="neutral05"
      padding={rowPadding}
      justifyContent="center"
      alignItems="center"
      gridGap={gridGap}
      dataTestId="imagesRow"
    >
      {images.map((image) => (
        <img
          className={day1StepsImageClassName}
          width={image.widthPx}
          height={image.heightPx}
          src={image.src}
          alt={intl.formatMessage({ id: image.altTextLocaleId })}
          key={uuidv4()}
        />
      ))}
    </Row>
  );
}

import en from "./en";
import fr from "./fr";
import id from "./id";
import { LOCALES } from "./locales";
import nl from "./nl";
import vi from "./vi";

const messages = {
  [LOCALES.ENGLISH]: en,
  [LOCALES.FRENCH]: fr,
  [LOCALES.VIETNAMESE]: vi,
  [LOCALES.DUTCH]: nl,
  [LOCALES.INDONESIAN]: id,
};

export default messages;

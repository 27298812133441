import { Col } from "@amzn/stencil-react-components/layout";
import { Text } from "@amzn/stencil-react-components/text";
import { FormattedMessage } from "react-intl";

import ErrorBanner from "@/components/Commons/ErrorBanner/ErrorBanner";

/**
 * Error message when the D1W failed to initialize correctly.
 * @constructor
 *
 * TODO: Revert this change after fixing this https://t.corp.amazon.com/V1599226697 issue
 */
export const AppNotAvailableError = () => {
  return (
    <ErrorBanner>
      <Text>
        <Col gridGap="S300">
          <FormattedMessage id="error-serviceNotAvailable-text1" />
          <Col gridGap="S200">
            <Text fontWeight="bold">
              <FormattedMessage id="error-serviceNotAvailable-step1-title" />
            </Text>
            <Text fontWeight="medium">
              <FormattedMessage id="error-serviceNotAvailable-step1-windows" />
            </Text>
            <ol type="a">
              <Col gridGap="S100">
                <li>
                  <FormattedMessage id="error-serviceNotAvailable-step1-windows-subStep1" />
                </li>
                <li>
                  <FormattedMessage id="error-serviceNotAvailable-step1-windows-subStep2" />
                </li>
                <li>
                  <FormattedMessage id="error-serviceNotAvailable-step1-windows-subStep3" />
                </li>
                <li>
                  <Col>
                    <Text>
                      <FormattedMessage id="error-serviceNotAvailable-step1-windows-subStep4a" />
                    </Text>
                    <Text>
                      <FormattedMessage id="error-serviceNotAvailable-step1-windows-subStep4b" />
                    </Text>
                    <Text>
                      <FormattedMessage id="error-serviceNotAvailable-step1-windows-subStep4c" />
                    </Text>
                  </Col>
                </li>
                <li>
                  <FormattedMessage id="error-serviceNotAvailable-step1-windows-subStep5" />
                </li>
              </Col>
            </ol>
            <Text fontWeight="medium">
              <FormattedMessage id="error-serviceNotAvailable-step1-mac" />
            </Text>
            <ol type="a">
              <li>
                <FormattedMessage id="error-serviceNotAvailable-step1-mac-subStep1" />
              </li>
            </ol>
            <Text fontWeight="bold">
              <FormattedMessage id="error-serviceNotAvailable-step2-title" />
            </Text>
            <Text>
              <FormattedMessage id="error-serviceNotAvailable-text2" />
            </Text>
          </Col>
        </Col>
      </Text>
    </ErrorBanner>
  );
};

import { LOCALES } from "./locales";

/**
 * Loads Panther .puff.json translations in the correct format for IntlProvider for a given locale.
 *
 * @param locale - The locale code (e.g., 'en-US', 'es-ES')
 * @returns A promise that resolves to a record of translation keys and their corresponding strings
 */
export const loadTranslations = async (
  locale: string
): Promise<Record<string, string>> => {
  try {
    const translations = (await import(
      `../generatedTranslations/${locale}`
    )) as { default: Record<string, string> };
    return translations.default;
  } catch (error) {
    console.error(`Failed to load translations for locale: ${locale}`, error);
    // Fallback to English if the requested locale is not available
    const translations = (await import(
      `../generatedTranslations/${LOCALES.ENGLISH}`
    )) as { default: Record<string, string> };
    return translations.default;
  }
};

/**
 * Loads default messages (en-US translations) mainly for testing.
 *
 * @returns A promise that resolves to a record of translation keys and their corresponding strings in English (en-US)
 */
export const loadDefaultMessages = async (): Promise<
  Record<string, string>
> => {
  return loadTranslations(LOCALES.ENGLISH);
};

import { IdvPreference } from "@amzn/client-workbench-api-model";
import { VideoRecording } from "@amzn/itsvideo-recorder";
import { StructuredEvent } from "@snowplow/tracker-core";

import { VerificationStatus } from "@/apis/IdentityCheckHelper";
import { ChatSupportContextReason } from "@/components/GetSupport/ChatWithITSupport/constants";
import { OPERATING_SYSTEM } from "@/helpers/os-helper";
import { WizardPathType } from "@/stores/constants";
import { Image, VideoFileConfig } from "@/types/common";
import {
  Day1EventAction,
  Day1EventCategory,
  Day1EventLabel,
} from "@/types/snowplow-events";

export const activeStepClassName = "active-step";
export const disabledStepClassName = "disabled-step";
export const day1StepsViewClassName = "its-day1-steps-view";
export const day1StepsImageClassName = "step-image";
export const day1StepsGridViewPadding: string[] = ["S200", "S500"];
export const ITS_LIGHT_TEAL = "#F5FAFB";
export type JourneyMetricsVerificationStatusArray = {
  verificationStatus?: VerificationStatus;

  // Represents status of RIV since this can change the journey metrics.
  IVVResult?: "PASS" | "REJECTED" | "EXPIRED";
  se: StructuredEvent;
}[];
export interface VideoRecordingSubmission extends VideoRecording {
  // A flag shows if NHs have clicked the submit button.
  submitClicked: boolean;
}

export interface StepsList {
  /**
   * The locale id of the step. It will be translated into the instruction mapped in the locale file.
   */
  stepLocaleId: string;

  /** Image that will be displayed to guide the NH */
  image?: Image;

  /**
   * Additional components that will be rendered as children within a step.
   */
  children?: JSX.Element;
}

/**
 * The ID that will be assigned to each step.
 */
export enum STEP_ID {
  IDENTITY_CHECK = "IdentityCheck",
  AMAZON_USERNAME = "AmazonUsername",
  REGISTER_SECURITY_KEY = "RegisterSecurityKey",
  CONNECT_VPN = "ConnectVpn",
  AMAZON_PASSWORD = "AmazonPassword",
  PRE_CACHE_AD_MOBILE = "PreCacheADMobile",
}

/**
 * The step interface which contains the state of each step.
 */
export interface Step {
  /**
   * The id assigned to the step.
   */
  id: STEP_ID;

  /**
   * The locale id that is needed to render the title of the expander of the step.
   */
  titleId: string;

  /**
   * The locale id that is needed to render the next button of the step.
   */
  nextButtonTitleId: string;

  /**
   * The i18n id that will be used to map into the disabled tooltip text.
   */
  nextButtonDisabledI18nId?: string;

  /**
   * True if the requirements of the steps are not completed before moving to the next step. Otherwise, return
   * false to allow NHs to advance to the next step.
   */
  nextDisabled?: boolean;

  /**
   * True if the previous step is not completed. Otherwise, return false to mark the step as active.
   */
  disabled?: boolean;

  /**
   * True if the step is completed to render the checkmark icon and collapses the expander. Otherwise, return false
   * to render the step number.
   */
  completed: boolean;

  /**
   * True if NHs are working on the step to expand the step expander. Otherwise, return false to keep the expander
   * collapsed.
   */
  expandable: boolean;

  /**
   * Only show the step if NH is using an operating system from the list if the list is specified.
   */
  platforms?: string[];

  /**
   * Should the next button be hidden.
   */
  hideNext?: boolean;

  /**
   * The reason why NHs contact the ITSE when they first land on the step. We need to track this through Snowplow
   * and update the search params of the IT support chat link accordingly.
   */
  nonDirectedChatSupportContext?: ChatSupportContextReason;

  /**
   * The metrics map used  to provide continue metrics depending on the verification status. This is an edge case for metrics used for RIV metrics.
   */
  continueByVerificationStatusMetrics?: JourneyMetricsVerificationStatusArray;

  /**
   * The event category to use. Used to generate snowplow event metrics for continue and expanded metrics.
   */
  eventCategory?: Day1EventCategory;
}

/**
 * All steps with their state data. It is used to loaded into the context values of the AppProvider.
 */
export const DAY1_ONBOARDING_STEPS: Step[] = [
  {
    id: STEP_ID.IDENTITY_CHECK,
    titleId: "identityCheck-expander-title",
    nextButtonTitleId: "button-next",
    nextDisabled: true,
    hideNext: true,
    completed: false,
    expandable: true,
    continueByVerificationStatusMetrics: [
      {
        verificationStatus: VerificationStatus.FULL_SUCCESS,
        se: {
          category: Day1EventCategory.IDCheckFullWait,
          action: Day1EventAction.Continue,
        },
      },
      {
        verificationStatus: VerificationStatus.LIGHT_SUCCESS,
        se: {
          category: Day1EventCategory.IDCheckLightWait,
          action: Day1EventAction.Continue,
        },
      },
      {
        verificationStatus: VerificationStatus.AUTOMATED_IDV_SUCCESS,
        se: {
          category: Day1EventCategory.AutomatedRivOutcome,
          action: Day1EventAction.Continue,
          label: Day1EventLabel.ResultPass,
        },
      },
      {
        verificationStatus: VerificationStatus.AUTOMATED_IDV_INFLIGHT,
        se: {
          category: Day1EventCategory.AutomatedRivOutcome,
          action: Day1EventAction.SectionToggled,
          label: Day1EventLabel.WaitForResult,
        },
      },
    ],
  },
  {
    id: STEP_ID.AMAZON_USERNAME,
    titleId: "amazonUsername-expander-title",
    nextButtonTitleId: "button-next",
    nextDisabled: true,
    completed: false,
    expandable: true,
    nonDirectedChatSupportContext: "ON_USERNAME_STEP",
    eventCategory: Day1EventCategory.AmazonUsername,
  },
  {
    id: STEP_ID.REGISTER_SECURITY_KEY,
    titleId: "registerSecurityKey-expander-title",
    nextButtonTitleId: "button-next",
    nextDisabled: true,
    completed: false,
    expandable: true,
    nonDirectedChatSupportContext: "ON_MIDWAY_STEP",
    eventCategory: Day1EventCategory.MidwayRegisterSecKey,
  },
  {
    id: STEP_ID.CONNECT_VPN,
    titleId: "connectVpn-expander-title",
    nextButtonTitleId: "button-next",
    nextDisabled: true,
    completed: false,
    expandable: true,
    nonDirectedChatSupportContext: "ON_CONNECT_TO_VPN_STEP",
    eventCategory: Day1EventCategory.ConnectToVpn,
  },
  {
    id: STEP_ID.AMAZON_PASSWORD,
    titleId: "amazonPassword-expander-title",
    nextButtonTitleId: "button-next",
    nextDisabled: true,
    completed: false,
    expandable: true,
    nonDirectedChatSupportContext: "ON_CREATE_PASSWORD_STEP",
    eventCategory: Day1EventCategory.CreateAmazonPassword,
  },
  {
    id: STEP_ID.PRE_CACHE_AD_MOBILE,
    titleId: "preCacheADMobile-expander-title",
    nextButtonTitleId: "button-next",
    nextDisabled: false,
    completed: false,
    expandable: true,
    platforms: [OPERATING_SYSTEM.MAC],
    nonDirectedChatSupportContext: "ON_PRECACHE_STEP",
    eventCategory: Day1EventCategory.InstallPrecache,
  },
];

/**
 * Videos that are used for guiding NHs in different onBoarding steps.
 */
export enum VideoGuidanceName {
  PRIMARY_ID_DESKTOP = "PRIMARY_ID_DESKTOP",
  SECONDARY_ID_DESKTOP = "SECONDARY_ID_DESKTOP",
  CONNECT_TO_VPN_MAC = "CONNECT_TO_VPN_MAC",
  CONNECT_TO_VPN_WINDOWS = "CONNECT_TO_VPN_WINDOWS",
}

/**
 * A config map which contains the videos with their configs such as filename and fileType.
 * The videos will be loaded to guide NHs during their onBoarding processes.
 */
export const VIDEOS_PLAYBACK_CONFIGS: Record<
  VideoGuidanceName,
  VideoFileConfig
> = {
  PRIMARY_ID_DESKTOP: {
    fileNameOrSrcUrl: {
      fileName: "primaryId-desktop-animation.mp4",
    },
    fileType: "video/mp4",
  },
  SECONDARY_ID_DESKTOP: {
    fileNameOrSrcUrl: {
      fileName: "secondaryId-desktop-animation.mp4",
    },
    fileType: "video/mp4",
  },
  CONNECT_TO_VPN_MAC: {
    fileNameOrSrcUrl: {
      fileName: "connect-to-vpn-mac.mp4",
    },
    fileType: "video/mp4",
  },
  CONNECT_TO_VPN_WINDOWS: {
    fileNameOrSrcUrl: {
      fileName: "connect-to-vpn-windows.mp4",
    },
    fileType: "video/mp4",
  },
};

export const CONNECT_TO_VPN_STEP_NUM =
  DAY1_ONBOARDING_STEPS.findIndex((step) => step.id === STEP_ID.CONNECT_VPN) +
  1;

/**
 * A map which contains the mapping of the idv preference to the wizard path type.
 */
export const IDV_PREFERENCE_TO_WIZARD_PATH_TYPE: Record<
  IdvPreference,
  WizardPathType | undefined
> = {
  NONE: undefined,
  AUTOMATED: "IVV",
  MANUAL: "ManualRIV",
};

/**
 * The appConfig key for default IDV rules
 */
export const APP_CONFIG_IDV_RULES_DEFAULT_KEY = "GENERIC";

/**
 * A look up table for the country codes associated with all the world's countries.
 * @see https://code.amazon.com/packages/ClientWorkbenchApiCDK/blobs/341ab9679c5fb5e46d9e38e15bebe9115a62f445/--/application-configuration/utils/countryNameCodeMapping.json
 */
export const COUNTRY_CODES_NAME_MAP = {
  ABW: "Aruba",
  AFG: "Afghanistan",
  AGO: "Angola",
  AIA: "Anguilla",
  ALA: "\u00c5land Islands",
  ALB: "Albania",
  AND: "Andorra",
  ARE: "United Arab Emirates",
  ARG: "Argentina",
  ARM: "Armenia",
  ASM: "American Samoa",
  ATA: "Antarctica",
  ATF: "French Southern Territories (the)",
  ATG: "Antigua and Barbuda",
  AUS: "Australia",
  AUT: "Austria",
  AZE: "Azerbaijan",
  BDI: "Burundi",
  BEL: "Belgium",
  BEN: "Benin",
  BES: "Bonaire, Sint Eustatius and Saba",
  BFA: "Burkina Faso",
  BGD: "Bangladesh",
  BGR: "Bulgaria",
  BHR: "Bahrain",
  BHS: "Bahamas (the)",
  BIH: "Bosnia and Herzegovina",
  BLM: "Saint Barth\u00e9lemy",
  BLR: "Belarus",
  BLZ: "Belize",
  BMU: "Bermuda",
  BOL: "Bolivia (Plurinational State of)",
  BRA: "Brazil",
  BRB: "Barbados",
  BRN: "Brunei",
  BTN: "Bhutan",
  BVT: "Bouvet Island",
  BWA: "Botswana",
  CAF: "Central African Republic (the)",
  CAN: "Canada",
  CCK: "Cocos (Keeling) Islands (the)",
  CHE: "Switzerland",
  CHL: "Chile",
  CHN: "China",
  CIV: "C\u00f4te d'Ivoire",
  CMR: "Cameroon",
  COD: "Congo (the Democratic Republic of the)",
  COG: "Congo (the)",
  COK: "Cook Islands (the)",
  COL: "Colombia",
  COM: "Comoros (the)",
  CPV: "Cabo Verde",
  CRI: "Costa Rica",
  CUB: "Cuba",
  CUW: "Cura\u00e7ao",
  CXR: "Christmas Island",
  CYM: "Cayman Islands (the)",
  CYP: "Cyprus",
  CZE: "Czech Republic",
  DEU: "Germany",
  DJI: "Djibouti",
  DMA: "Dominica",
  DNK: "Denmark",
  DOM: "Dominican Republic (the)",
  DZA: "Algeria",
  ECU: "Ecuador",
  EGY: "Egypt",
  ERI: "Eritrea",
  ESH: "Western Sahara*",
  ESP: "Spain",
  EST: "Estonia",
  ETH: "Ethiopia",
  FIN: "Finland",
  FJI: "Fiji",
  FLK: "Falkland Islands (the) [Malvinas]",
  FRA: "France",
  FRO: "Faroe Islands (the)",
  FSM: "Micronesia (Federated States of)",
  GAB: "Gabon",
  GBR: "United Kingdom",
  GEO: "Georgia",
  GGY: "Guernsey",
  GHA: "Ghana",
  GIB: "Gibraltar",
  GIN: "Guinea",
  GLP: "Guadeloupe",
  GMB: "Gambia (the)",
  GNB: "Guinea-Bissau",
  GNQ: "Equatorial Guinea",
  GRC: "Greece",
  GRD: "Grenada",
  GRL: "Greenland",
  GTM: "Guatemala",
  GUF: "French Guiana",
  GUM: "Guam",
  GUY: "Guyana",
  HKG: "Hong Kong",
  HMD: "Heard Island and McDonald Islands",
  HND: "Honduras",
  HRV: "Croatia",
  HTI: "Haiti",
  HUN: "Hungary",
  IDN: "Indonesia",
  IMN: "Isle of Man",
  IND: "India",
  IOT: "British Indian Ocean Territory (the)",
  IRL: "Ireland",
  IRN: "Iran (Islamic Republic of)",
  IRQ: "Iraq",
  ISL: "Iceland",
  ISR: "Israel",
  ITA: "Italy",
  JAM: "Jamaica",
  JEY: "Jersey",
  JOR: "Jordan",
  JPN: "Japan",
  KAZ: "Kazakhstan",
  KEN: "Kenya",
  KGZ: "Kyrgyzstan",
  KHM: "Cambodia",
  KIR: "Kiribati",
  KNA: "Saint Kitts and Nevis",
  KOR: "Korea (the Republic of)",
  KWT: "Kuwait",
  LAO: "Lao People's Democratic Republic (the)",
  LBN: "Lebanon",
  LBR: "Liberia",
  LBY: "Libya",
  LCA: "Saint Lucia",
  LIE: "Liechtenstein",
  LKA: "Sri Lanka",
  LSO: "Lesotho",
  LTU: "Lithuania",
  LUX: "Luxembourg",
  LVA: "Latvia",
  MAC: "Macao",
  MAF: "Saint Martin (French part)",
  MAR: "Morocco",
  MCO: "Monaco",
  MDA: "Moldova (the Republic of)",
  MDG: "Madagascar",
  MDV: "Maldives",
  MEX: "Mexico",
  MHL: "Marshall Island",
  MKD: "North Macedonia",
  MLI: "Mali",
  MLT: "Malta",
  MMR: "Myanmar",
  MNE: "Montenegro",
  MNG: "Mongolia",
  MNP: "Northern Mariana Islands (the)",
  MOZ: "Mozambique",
  MRT: "Mauritania",
  MSR: "Montserrat",
  MTQ: "Martinique",
  MUS: "Mauritius",
  MWI: "Malawi",
  MYS: "Malaysia",
  MYT: "Mayotte",
  NAM: "Namibia",
  NCL: "New Caledonia",
  NER: "Niger (the)",
  NFK: "Norfolk Island",
  NGA: "Nigeria",
  NIC: "Nicaragua",
  NIU: "Niue",
  NLD: "Netherlands",
  NOR: "Norway",
  NPL: "Nepal",
  NRU: "Nauru",
  NZL: "New Zealand",
  OMN: "Oman",
  PAK: "Pakistan",
  PAN: "Panama",
  PCN: "Pitcairn",
  PER: "Peru",
  PHL: "Philippines",
  PLW: "Palau",
  PNG: "Papua New Guinea",
  POL: "Poland",
  PRI: "Puerto Rico",
  PRK: "South Korea",
  PRT: "Portugal",
  PRY: "Paraguay",
  PSE: "Palestine, State of",
  PYF: "French Polynesia",
  QAT: "Qatar",
  REU: "R\u00e9union",
  ROU: "Romania",
  RUS: "Russia",
  RWA: "Rwanda",
  SAU: "Saudi Arabia",
  SDN: "Sudan (the)",
  SEN: "Senegal",
  SGP: "Singapore",
  SGS: "South Georgia and the South Sandwich Islands",
  SHN: "Saint Helena, Ascension and Tristan da Cunha",
  SJM: "Svalbard and Jan Mayen",
  SLB: "Solomon Islands",
  SLE: "Sierra Leone",
  SLV: "El Salvador",
  SMR: "San Marino",
  SOM: "Somalia",
  SPM: "Saint Pierre and Miquelon",
  SRB: "Serbia",
  SSD: "South Sudan",
  STP: "Sao Tome and Principe",
  SUR: "Suriname",
  SVK: "Slovakia",
  SVN: "Slovenia",
  SWE: "Sweden",
  SWZ: "Eswatini",
  SXM: "Sint Maarten (Dutch part)",
  SYC: "Seychelles",
  SYR: "Syrian Arab Republic (the)",
  TCA: "Turks and Caicos Islands (the)",
  TCD: "Chad",
  TGO: "Togo",
  THA: "Thailand",
  TJK: "Tajikistan",
  TKL: "Tokelau",
  TKM: "Turkmenistan",
  TLS: "Timor-Leste",
  TON: "Tonga",
  TTO: "Trinidad and Tobago",
  TUN: "Tunisia",
  TUR: "Turkey",
  TUV: "Tuvalu",
  TWN: "Taiwan (Republic of China)",
  TZA: "Tanzania, the United Republic of",
  UGA: "Uganda",
  UKR: "Ukraine",
  UMI: "United States Minor Outlying Islands (the)",
  URY: "Uruguay",
  USA: "United States",
  UZB: "Uzbekistan",
  VAT: "Holy See (the)",
  VCT: "Saint Vincent and the Grenadines",
  VEN: "Venezuela (Bolivarian Republic of)",
  VGB: "Virgin Islands (British)",
  VIR: "Virgin Islands (U.S.)",
  VNM: "Vietnam",
  VUT: "Vanuatu",
  WLF: "Wallis and Futuna",
  WSM: "Samoa",
  YEM: "Yemen",
  ZAF: "South Africa",
  ZMB: "Zambia",
  ZWE: "Zimbabwe",
} as const satisfies Record<string, string>;
